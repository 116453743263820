import slick from '../plugins/slick.js';
import Headroom from '../plugins/headroom.js';

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Masonry
      function masonry() {
        $('.masonry').each(function(){
          var $this = $(this);
          if ($this.prev('.masonry').prev('.masonry').length > 0) {
            var $offset = $this.offset().top - $this.prev('.masonry').prev('.masonry').offset().top - $this.prev('.masonry').prev('.masonry').outerHeight();
            console.log($offset);
            if ($offset > 10) {
              $offset = '-' + $offset;
              $this.css('margin-top', $offset + 'px');
            }
          }
        });
      }
      $(window).bind('load', function(){
        masonry();
      });

      // Project Gallery
      $('.gallery-toggle').click(function(e){
        e.preventDefault();
        $('.gallery-popup').addClass('open');
        $('.gallery-slider').slick('slickGoTo',$(this).data('slide'));
      });
      $('.close-gallery').click(function(e){
        e.preventDefault();
        $('.gallery-popup').removeClass('open');
      });

      // Gallery Slider
      $('.gallery-slider').slick({
        dots: true,
        infinite: true,
        speed: 1000,
        fade: true,
        autoplay: false,
        slidesToShow: 1,
        variableWidth: false,
        prevArrow: '<span class="prev">&nbsp;</span>',
        nextArrow: '<span class="next">&nbsp;</span>',
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              autoplay: false,
            }
          }
        ]
      });

      // Sticky Header
      var myElement = document.querySelector(".global-header");
      var headroom  = new Headroom(myElement, {
        "offset": 150,
        "tolerance": 0
      });
      headroom.init();

      // Search
      $('.search-toggle').click(function(e){
        e.preventDefault();
        $('#search-popup').addClass('active');
        $('#search-input').focus();
      });
      $('.close-search').click(function(){
        $('#search-popup').removeClass('active');
      });

      // Cookied Popup
      $('.close-cookie-popup').on('click', function(){
        $('.cookied-popup-wrapper').css('display', 'none');
        $.cookie('popup', $('.cookied-popup-wrapper').attr('id'), { path: '/' });
      });

      // Status Bar
      $('#status-close').on('click', function(){
        $('.status-bar').css('display', 'none');
        $.cookie('status', $('.status-bar').attr('id'), { path: '/' });
      });

      // Pagination
      $('#pagination').on('click', 'a', function(e){
        e.preventDefault();
        $(this).remove();
        $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('#ajax-posts').append($('#ajax-loader').html());
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Anchor
      $('a').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href.match('^#') && !$(this).hasClass('accordion-tab')) {
          e.preventDefault();
          $('html, body').stop().animate({
              scrollTop: $($href).offset().top
          });
        }
      });

      // Animations
      $('.animate').addClass('active');

      // Form Labels
      $('input').on('focus', function(){
        $(this).parent('*').addClass('filled');
      });
      $('input').on('blur', function(){
        if ($(this).val() !== '') {
          $(this).parent('*').addClass('filled');
        } else {
          $(this).parent('*').removeClass('filled');
        }
      });

      // Video popups
      $('a').on('click', function(e){
        var $target = $(this).attr('href');
        if ($target.toLowerCase().indexOf("vimeo") >= 0) {
          e.preventDefault();
          var $n = $target.lastIndexOf('/');
          var $vid = $target.substring($n + 1);
          $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("youtu") >= 0) {
          e.preventDefault();
          var $y = $target.lastIndexOf('=');
          var $yid = $target.substring($y + 1);
          $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
      });
      $('.close-popup').on('click', function(){
        $('.video-popup-wrapper').removeClass('active');
        $('.popup-content').html('');
        $('body, html').removeClass('body-noscroll');
      });

      // Mobile Nav
      $('#mobile-nav-toggle').on('click', function(){
        var $navheight = $('#mobile-navigation').height() + 170;
        $('body, html').toggleClass('nav-locked');
        $('#mobile-navigation-wrapper').toggleClass('active');
        $(this).toggleClass('active');
        window.scrollTo(0, 0);
      });

      // Resize for Match-Height and Slick-Slider glitch fix
      $(window).bind('load', function(){
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
      });
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.